<template>
  <div>
    <a-spin size="large" :spinning="spinning">
      <a-page-header title="返回" @back="goBack" />
      <a-form-model
        ref="ruleForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item>
          <span class="info">基本信息</span>
        </a-form-model-item>
        <a-row>
          <a-col :md="10" :sm="24" style="margin-left: 80px">
            <a-form-item
              label="账号："
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 16, offset: 1 }"
            >
              <a-input
                v-model="form.staff_phone"
                placeholder="请输入"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="24">
            <a-form-item
              label="真实姓名："
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 16, offset: 1 }"
            >
              <a-input
                v-model="form.staff_name"
                placeholder="请输入"
                :disabled="true"
              />
            </a-form-item>
          </a-col>

          <a-col :md="10" :sm="24" style="margin-left: 80px">
            <a-form-item
              label="手机号："
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 16, offset: 1 }"
            >
              <a-input
                v-model="form.staff_phone"
                placeholder="请输入"
                :disabled="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-table
              :columns="columns"
              :data-source="rolesData"
              bordered
              :pagination="false"
              style="margin-left: 100px;margin-right: 100px;margin-bottom: 20px;"
            >
            </a-table>
          </a-col>
          <a-form-model-item>
            <span class="info">工作信息</span>
          </a-form-model-item>
          <a-col :span="24">
            <a-table
              :columns="table.columns"
              :data-source="table.data"
              style="margin-left: 100px; margin-right: 100px; margin-top: 20px"
              bordered
            >
            </a-table>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import * as api from '@/api/enterprise/enterprise.js'
import moment from "moment";

export default {
  data() {
    return {
      spinning: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        //员工名称
        staff_name: "",
        //员工手机号
        staff_phone: "",
        //员工账号
        card_number: "",
      },
      table: {
        columns: [
          {
            title: "企业名称",
            dataIndex: "enterprise_name",
          },
          {
            title: "加入日期",
            dataIndex: "showCreateDate",
            defaultSortOrder: "descend",
            sorter: (a, b) => {
              return a.sortCreateDate - b.sortCreateDate;
            },
          },
          {
            title: "退出日期",
            dataIndex: "showEndDate",
            filterMultiple: false,
            sorter: (a, b) => {
              return a.sortEndDate - b.sortEndDate;
            },
            sortDirections: ["descend", "ascend"],
          },
          {
            title: "部门",
            dataIndex: "depart_name",
          },
          {
            title: "企业角色",
            dataIndex: "role_name",
          },
        ],
        data: [],
      },
      columns: [
        {
          key: "enterprise_name",
          title: "企业名称",
          dataIndex: "enterprise_name",
          width: "50%",
          align: "center",
        },
        {
          title: "角色",
          className: "column-role",
          dataIndex: "role_name",
          width: "50%",
          align: "center",
        },
      ],
      rolesData: [],
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },

    /**网络请求 */
    //获取员工详情
    async getStaffDetail() {
      try {
        this.spinning = true;
        const { data }= await api.getStaffDetail(this.$route.query.staffId);
        this.spinning = false;
        if (data.code === "200") {
          this.form = {
            staff_name: data.data.staff_name,
            staff_phone: data.data.staff_phone,
            card_number: data.data.card_number,
          };
          this.rolesData = data.data.enterprise_roles;
          this.table.data = data.data.enterprise_roles;
          this.table.data.forEach((item) => {
            this.$set(
              item,
              "showCreateDate",
              moment(item.create_at).format("YYYY-MM-DD")
            );
            this.$set(item, "sortCreateDate", moment(item.create_at).unix());
            if (item.end_at) {
              this.$set(
                item,
                "showEndDate",
                moment(item.end_at).format("YYYY-MM-DD")
              );
              this.$set(item, "sortEndDate", moment(item.end_at).unix());
            } else {
              this.$set(item, "showEndDate", "未退出");
              this.$set(item, "sortEndDate", 0);
            }
          });
        }
        if (data.code === "400") {
          this.$message.error(data.msg)
        }
      } catch (error) {
        this.spinning = false;
      }
    },
    goBack() {
      this.$router.go(-1)
      // 针对 path === look 时 返回到 企业列表-人员列表
      // if (this.$route.query.from === "look") {
      //   this.$router.push({ name: "look", query: {id: this.$route.query.enterprise_id} });
      // } else {
      //   this.$router.push({
      //     name: "userList",
      //     params: {
      //       type: "view",
      //     },
      //   });
      // }
    },
  },
  created() {
    this.getStaffDetail();
  },
};
</script>
